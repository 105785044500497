// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

export const EMPTY_VALUE = '—';
export const EPSILON = 1e-30; // tolerance used for checking non-zero quantities
export const EMPTY_2D_ARRAY = [[0]];
export const SECONDS_IN_DAY = 86400;
export const MESH_MAX_CELLS = 200_000_000;
export const MAX_STOPPING_COND_INNER_ITERATIONS = 70;
export const MAX_STOPPING_COND_ITERATIONS = 30000;
// A practical limit on mesh adaptation. Increase with remeshing performance.
// Keep in sync with maxAdaptFinalCellsInMillions in
// go/core/protoutil/fvmparam.go
export const MAX_ADAPT_FINAL_CELLS_IN_MILLIONS = 50;

export const APP_URL_PROD = 'https://app.luminarycloud.com';
export const APP_URL_PROD_ITAR = 'https://app-itar.luminarycloud.com';
export const APP_URL_STAGE = 'https://test0.int.luminarycloud.com/';
export const APP_URL_STAGE_ITAR = 'https://itar-staging.int.luminarycloud.com/';
export const APP_URL_DEV = 'https://main.int.luminarycloud.com/';
export const APP_URL_DEV_ITAR = 'https://itar-main.int.luminarycloud.com/';

export const SUPPORT_EMAIL = 'support@luminarycloud.com';
export const HOME_URL = 'https://www.luminarycloud.com';
export const SIGN_UP_URL = 'https://www.luminarycloud.com/waitlist/';
export const SUPPORT_URL = 'https://support.luminarycloud.com';
export const LEARNING_URL = 'https://docs.luminarycloud.com';
export const EARLY_ACCESS_URL = 'https://docs.luminarycloud.com/en/articles/9416093-early-access';
export const BATCH_PROCESSING_URL = 'https://docs.luminarycloud.com/en/articles/9369488-run-a-simulation#h_df8385bc0f';
export const AUTH0_OTP_MFA_APPS_URL = 'https://auth0.com/docs/secure/multi-factor-authentication/multi-factor-authentication-factors/configure-otp-notifications-for-mfa';
export const LIBRARY_URL = 'https://docs.luminarycloud.com/en/collections/9411885-library';

export const LEARNING_PIPER_CHEROKEE_TUTORIAL_URL = 'https://docs.luminarycloud.com/en/collections/9479917-piper-cherokee';
export const LEARNING_PROJECTS_GUIDE_URL = 'https://docs.luminarycloud.com/en/collections/9375554-projects';
export const LEARNING_SHARING_PROJECTS_URL = 'https://docs.luminarycloud.com/en/articles/9401782-share-projects';
export const LEARNING_CAD_MODELS_URL = 'https://docs.luminarycloud.com/en/collections/7291547-cad-preparation';
export const LEARNING_ACCOUNT_USAGE_URL = 'https://docs.luminarycloud.com/en/collections/9479860-billing-and-usage';

export const MIXPANEL_TOKEN_DEV = '749f26e8e0919745584a09f628a02626';
export const MIXPANEL_TOKEN_PROD = '47002fd379e347f5ceb5ec497b8ad373';

// *** DO NOT CHANGE THESE WITHOUT LEGAL APPROVAL ***
export const TOS_URL = 'http://legal.luminarycloud.com/terms.html';
export const EVAL_URL = 'http://legal.luminarycloud.com/eval-order-form.html';
export const PRIVACY_URL = 'http://legal.luminarycloud.com/privacy.html';

export const MAX_PHYSICS = 5;
export const MAX_HEAT_PHYSICS = 1;

// J/K/mol
export const UNIVERSAL_GAS_CONSTANT = 8.3144598;

// Reused validation messages
export const UNSUPPORTED_LMA = (
  'Lumi Mesh Adaptation does not support multiphysics, transition modeling, or overset meshes.'
);
export const UNSUPPORTED_MULTIPHYS_LMA = (
  'Lumi Mesh Adaptation does not support multiphysics simulations.'
);
export const UNSUPPORTED_HEAT_LMA = (
  'Lumi Mesh Adaptation does not support heat transfer.'
);

export const UNSUPPORTED_MULTIPHYS_MOTION = 'Motion is only supported with a single fluid physics';
