// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=js+dts"
// @generated from file proto/api/v0/luminarycloud/geometry/geometry.proto (package luminary.proto.api.v0.luminarycloud.geometry, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CheckGeometryRequest, CheckGeometryResponse, CreateGeometryRequest, CreateGeometryResponse, GetGeometryRequest, GetGeometryResponse, GetTagsRequest, GetTagsResponse, KeepAliveRequest, KeepAliveResponse, LatestTessellationRequest, LatestTessellationResponse, ListGeometriesRequest, ListGeometriesResponse, ModifyGeometryRequest, ModifyGeometryResponse, PanicRequest, PanicResponse, StopWorkerRequest, StopWorkerResponse, SubscribeGeometryRequest, SubscribeGeometryResponse, TessellationUpToModificationRequest, TessellationUpToModificationResponse } from "./geometry_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service luminary.proto.api.v0.luminarycloud.geometry.GeometryService
 */
export const GeometryService = {
  typeName: "luminary.proto.api.v0.luminarycloud.geometry.GeometryService",
  methods: {
    /**
     * Lists the geometries available in a project.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.ListGeometries
     */
    listGeometries: {
      name: "ListGeometries",
      I: ListGeometriesRequest,
      O: ListGeometriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get details and current features of the specified geometry.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.GetGeometry
     */
    getGeometry: {
      name: "GetGeometry",
      I: GetGeometryRequest,
      O: GetGeometryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Subscribes the clients to the updates in the geometry state of a give geometry id.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.SubscribeGeometry
     */
    subscribeGeometry: {
      name: "SubscribeGeometry",
      I: SubscribeGeometryRequest,
      O: SubscribeGeometryResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Applies a modification to the currently loaded geometry. If the id of the
     * modification is found in the state, it overrides the modification and
     * replays all the operations.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.ModifyGeometry
     */
    modifyGeometry: {
      name: "ModifyGeometry",
      I: ModifyGeometryRequest,
      O: ModifyGeometryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Create a new geometry using the URL of a file that already exists in the
     * project.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.CreateGeometry
     */
    createGeometry: {
      name: "CreateGeometry",
      I: CreateGeometryRequest,
      O: CreateGeometryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Check the geometry and report any issues that may prevent meshing.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.CheckGeometry
     */
    checkGeometry: {
      name: "CheckGeometry",
      I: CheckGeometryRequest,
      O: CheckGeometryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Returns the tesselation information up to a given active modification ID.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.TesselationUpToModification
     */
    tesselationUpToModification: {
      name: "TesselationUpToModification",
      I: TessellationUpToModificationRequest,
      O: TessellationUpToModificationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Returns the latest tesselation of the last available version of the
     * geometry. The same information will be broadcast through the subscription
     * RPC.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.LatestTessellation
     */
    latestTessellation: {
      name: "LatestTessellation",
      I: LatestTessellationRequest,
      O: LatestTessellationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Keeps the worker alive only if the worker is up and running. Clients can
     * use this endpoint to make sure that the worker is not killed while the
     * client is not actively using the service.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.KeepAlive
     */
    keepAlive: {
      name: "KeepAlive",
      I: KeepAliveRequest,
      O: KeepAliveResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Crashes the service intentionally. Can only be called by staff users and in
     * non-production environments.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.Panic
     */
    panic: {
      name: "Panic",
      I: PanicRequest,
      O: PanicResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Tries to stop the worker and the manager of a given geometry. Can only be
     * called by staff users.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.StopWorker
     */
    stopWorker: {
      name: "StopWorker",
      I: StopWorkerRequest,
      O: StopWorkerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get the tags associated with a geometry version.
     *
     * @generated from rpc luminary.proto.api.v0.luminarycloud.geometry.GeometryService.GetTags
     */
    getTags: {
      name: "GetTags",
      I: GetTagsRequest,
      O: GetTagsResponse,
      kind: MethodKind.Unary,
    },
  }
};

