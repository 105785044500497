// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/frontend/simulation/simulation.proto (package luminary.proto.frontend.simulation, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, proto3, Timestamp } from "@bufbuild/protobuf";
import { SimulationParam } from "../../client/simulation_pb.js";
import { File } from "../../file/file_pb.js";
import { AdVector3 } from "../../base/base_pb.js";
import { ReferenceValues } from "../../output/reference_values_pb.js";
import { Param } from "../../fvm/param_pb.js";
import { GPUPreference } from "../../workflow/workflow_pb.js";

/**
 * @generated from enum luminary.proto.frontend.simulation.QuantityType
 */
export const QuantityType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.simulation.QuantityType",
  [
    {no: 0, name: "QUANTITY_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "QUANTITY_TYPE_LIFT", localName: "LIFT"},
    {no: 2, name: "QUANTITY_TYPE_DRAG", localName: "DRAG"},
    {no: 3, name: "QUANTITY_TYPE_SIDEFORCE", localName: "SIDEFORCE"},
    {no: 4, name: "QUANTITY_TYPE_ROLLING_MOMENT", localName: "ROLLING_MOMENT"},
    {no: 5, name: "QUANTITY_TYPE_YAWING_MOMENT", localName: "YAWING_MOMENT"},
    {no: 6, name: "QUANTITY_TYPE_PITCHING_MOMENT", localName: "PITCHING_MOMENT"},
    {no: 7, name: "QUANTITY_TYPE_TOTAL_FORCE", localName: "TOTAL_FORCE"},
    {no: 8, name: "QUANTITY_TYPE_DOWNFORCE", localName: "DOWNFORCE"},
    {no: 9, name: "QUANTITY_TYPE_DISK_THRUST", localName: "DISK_THRUST"},
    {no: 10, name: "QUANTITY_TYPE_FRICTION_FORCE", localName: "FRICTION_FORCE"},
    {no: 11, name: "QUANTITY_TYPE_FRICTION_FORCE_COEFFICIENT", localName: "FRICTION_FORCE_COEFFICIENT"},
    {no: 12, name: "QUANTITY_TYPE_PRESSURE_FORCE", localName: "PRESSURE_FORCE"},
    {no: 13, name: "QUANTITY_TYPE_PRESSURE_FORCE_COEFFICIENT", localName: "PRESSURE_FORCE_COEFFICIENT"},
    {no: 14, name: "QUANTITY_TYPE_LIFT_COEFFICIENT", localName: "LIFT_COEFFICIENT"},
    {no: 15, name: "QUANTITY_TYPE_DRAG_COEFFICIENT", localName: "DRAG_COEFFICIENT"},
    {no: 16, name: "QUANTITY_TYPE_TOTAL_FORCE_COEFFICIENT", localName: "TOTAL_FORCE_COEFFICIENT"},
    {no: 17, name: "QUANTITY_TYPE_SIDEFORCE_COEFFICIENT", localName: "SIDEFORCE_COEFFICIENT"},
    {no: 18, name: "QUANTITY_TYPE_DOWNFORCE_COEFFICIENT", localName: "DOWNFORCE_COEFFICIENT"},
    {no: 19, name: "QUANTITY_TYPE_TOTAL_MOMENT", localName: "TOTAL_MOMENT"},
    {no: 20, name: "QUANTITY_TYPE_DISK_TORQUE", localName: "DISK_TORQUE"},
    {no: 21, name: "QUANTITY_TYPE_TOTAL_MOMENT_COEFFICIENT", localName: "TOTAL_MOMENT_COEFFICIENT"},
    {no: 22, name: "QUANTITY_TYPE_ROLLING_MOMENT_COEFFICIENT", localName: "ROLLING_MOMENT_COEFFICIENT"},
    {no: 23, name: "QUANTITY_TYPE_YAWING_MOMENT_COEFFICIENT", localName: "YAWING_MOMENT_COEFFICIENT"},
    {no: 24, name: "QUANTITY_TYPE_PITCHING_MOMENT_COEFFICIENT", localName: "PITCHING_MOMENT_COEFFICIENT"},
    {no: 25, name: "QUANTITY_TYPE_PRESSURE", localName: "PRESSURE"},
    {no: 27, name: "QUANTITY_TYPE_TEMPERATURE", localName: "TEMPERATURE"},
    {no: 28, name: "QUANTITY_TYPE_DENSITY", localName: "DENSITY"},
    {no: 29, name: "QUANTITY_TYPE_TOTAL_PRESSURE", localName: "TOTAL_PRESSURE"},
    {no: 31, name: "QUANTITY_TYPE_TOTAL_TEMPERATURE", localName: "TOTAL_TEMPERATURE"},
    {no: 32, name: "QUANTITY_TYPE_MACH", localName: "MACH"},
    {no: 33, name: "QUANTITY_TYPE_VELOCITY_MAGNITUDE", localName: "VELOCITY_MAGNITUDE"},
    {no: 34, name: "QUANTITY_TYPE_Y_PLUS", localName: "Y_PLUS"},
    {no: 35, name: "QUANTITY_TYPE_ENERGY_FLUX", localName: "ENERGY_FLUX"},
    {no: 36, name: "QUANTITY_TYPE_VELOCITY_X", localName: "VELOCITY_X"},
    {no: 37, name: "QUANTITY_TYPE_VELOCITY_Y", localName: "VELOCITY_Y"},
    {no: 38, name: "QUANTITY_TYPE_VELOCITY_Z", localName: "VELOCITY_Z"},
  ],
);

/**
 * @generated from enum luminary.proto.frontend.simulation.CalculationType
 */
export const CalculationType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.simulation.CalculationType",
  [
    {no: 0, name: "CALCULATION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CALCULATION_TYPE_AGGREGATE", localName: "AGGREGATE"},
    {no: 2, name: "CALCULATION_TYPE_PER_SURFACE", localName: "PER_SURFACE"},
  ],
);

/**
 * @generated from enum luminary.proto.frontend.simulation.AveragingType
 */
export const AveragingType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.simulation.AveragingType",
  [
    {no: 0, name: "AVERAGING_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "AVERAGING_TYPE_MASS_FLOW", localName: "MASS_FLOW"},
    {no: 2, name: "AVERAGING_TYPE_AREA", localName: "AREA"},
  ],
);

/**
 * Represents a simulation object.
 *
 * @generated from message luminary.proto.frontend.simulation.Simulation
 */
export const Simulation = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.Simulation",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "create_time", kind: "message", T: Timestamp },
    { no: 4, name: "update_time", kind: "message", T: Timestamp },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(Simulation_SimulationStatus) },
    { no: 6, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Represents current status of a simulation.
 *
 * @generated from enum luminary.proto.frontend.simulation.Simulation.SimulationStatus
 */
export const Simulation_SimulationStatus = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.simulation.Simulation.SimulationStatus",
  [
    {no: 0, name: "SIMULATION_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SIMULATION_STATUS_PENDING", localName: "PENDING"},
    {no: 2, name: "SIMULATION_STATUS_ACTIVE", localName: "ACTIVE"},
    {no: 3, name: "SIMULATION_STATUS_COMPLETED", localName: "COMPLETED"},
    {no: 4, name: "SIMULATION_STATUS_FAILED", localName: "FAILED"},
    {no: 5, name: "SIMULATION_STATUS_SUSPENDED", localName: "SUSPENDED"},
  ],
);

/**
 * Various settings to configure how a simulation is run.
 *
 * @generated from message luminary.proto.frontend.simulation.SimulationOptions
 */
export const SimulationOptions = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.SimulationOptions",
  () => [
    { no: 1, name: "batch_processing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Used to create a simulation.
 *
 * @generated from message luminary.proto.frontend.simulation.CreateSimulationRequest
 */
export const CreateSimulationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.CreateSimulationRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "simulation_template_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "simulation_options", kind: "message", T: SimulationOptions },
    { no: 6, name: "simulation_param", kind: "message", T: SimulationParam },
    { no: 7, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Represents simulation created.
 *
 * @generated from message luminary.proto.frontend.simulation.CreateSimulationResponse
 */
export const CreateSimulationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.CreateSimulationResponse",
  () => [
    { no: 1, name: "simulation", kind: "message", T: Simulation },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationRequest
 */
export const GetSimulationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationResponse
 */
export const GetSimulationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationResponse",
  () => [
    { no: 1, name: "simulation", kind: "message", T: Simulation },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.UpdateSimulationRequest
 */
export const UpdateSimulationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.UpdateSimulationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.UpdateSimulationResponse
 */
export const UpdateSimulationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.UpdateSimulationResponse",
  () => [
    { no: 1, name: "simulation", kind: "message", T: Simulation },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.ListSimulationsRequest
 */
export const ListSimulationsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.ListSimulationsRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.ListSimulationsResponse
 */
export const ListSimulationsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.ListSimulationsResponse",
  () => [
    { no: 1, name: "simulations", kind: "message", T: Simulation, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.DeleteSimulationRequest
 */
export const DeleteSimulationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.DeleteSimulationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.SuspendSimulationRequest
 */
export const SuspendSimulationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.SuspendSimulationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationGlobalResidualsRequest
 */
export const GetSimulationGlobalResidualsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationGlobalResidualsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "residual_normalization", kind: "enum", T: proto3.getEnumType(GetSimulationGlobalResidualsRequest_ResidualNormalization) },
  ],
);

/**
 * Optional. Whether to return the absolute or relative residuals. If
 * unspecified, it defaults to relative normalization.
 *
 * @generated from enum luminary.proto.frontend.simulation.GetSimulationGlobalResidualsRequest.ResidualNormalization
 */
export const GetSimulationGlobalResidualsRequest_ResidualNormalization = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.simulation.GetSimulationGlobalResidualsRequest.ResidualNormalization",
  [
    {no: 0, name: "RESIDUAL_NORMALIZATION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "RESIDUAL_NORMALIZATION_RELATIVE", localName: "RELATIVE"},
    {no: 2, name: "RESIDUAL_NORMALIZATION_ABSOLUTE", localName: "ABSOLUTE"},
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationGlobalResidualsResponse
 */
export const GetSimulationGlobalResidualsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationGlobalResidualsResponse",
  () => [
    { no: 1, name: "csv_file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationSurfaceQuantityOutputRequest
 */
export const GetSimulationSurfaceQuantityOutputRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationSurfaceQuantityOutputRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "quantity_type", kind: "enum", T: proto3.getEnumType(QuantityType) },
    { no: 3, name: "surface_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "calculation_type", kind: "enum", T: proto3.getEnumType(CalculationType) },
    { no: 6, name: "frame_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "force_direction", kind: "message", T: AdVector3 },
    { no: 18, name: "moment_center", kind: "message", T: AdVector3 },
    { no: 19, name: "averaging_type", kind: "enum", T: proto3.getEnumType(AveragingType) },
    { no: 20, name: "reference_values", kind: "message", T: ReferenceValues },
  ],
);

/**
 * TODO: Add sample output here.
 *
 * @generated from message luminary.proto.frontend.simulation.GetSimulationSurfaceQuantityOutputResponse
 */
export const GetSimulationSurfaceQuantityOutputResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationSurfaceQuantityOutputResponse",
  () => [
    { no: 1, name: "csv_file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationSurfaceSolutionRequest
 */
export const GetSimulationSurfaceSolutionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationSurfaceSolutionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "time_step", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationSurfaceSolutionResponse
 */
export const GetSimulationSurfaceSolutionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationSurfaceSolutionResponse",
  () => [
    { no: 2, name: "file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationVolumeSolutionRequest
 */
export const GetSimulationVolumeSolutionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationVolumeSolutionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "time_step", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationVolumeSolutionResponse
 */
export const GetSimulationVolumeSolutionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationVolumeSolutionResponse",
  () => [
    { no: 2, name: "file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.EstimateSimulationRunTimeRequest
 */
export const EstimateSimulationRunTimeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.EstimateSimulationRunTimeRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fvm_param", kind: "message", T: Param },
    { no: 4, name: "gpu_pref", kind: "message", T: GPUPreference, repeated: true },
    { no: 5, name: "simulation_param", kind: "message", T: SimulationParam },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.EstimateSimulationRunTimeReply
 */
export const EstimateSimulationRunTimeReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.EstimateSimulationRunTimeReply",
  () => [
    { no: 1, name: "estimate", kind: "message", T: Duration },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationIdRequest
 */
export const GetSimulationIdRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationIdRequest",
  () => [
    { no: 1, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.GetSimulationIdReply
 */
export const GetSimulationIdReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.GetSimulationIdReply",
  () => [
    { no: 1, name: "simulation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.ListSimulationsWithMeshRequest
 */
export const ListSimulationsWithMeshRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.ListSimulationsWithMeshRequest",
  () => [
    { no: 1, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.simulation.ListSimulationsWithMeshResponse
 */
export const ListSimulationsWithMeshResponse = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.simulation.ListSimulationsWithMeshResponse",
  () => [
    { no: 1, name: "simulations", kind: "message", T: Simulation, repeated: true },
  ],
);

