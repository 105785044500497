// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// The next two lines must run other statements. Hence eslint-disable.
/* eslint-disable import/first, import/order */
import * as LogRocket from './lib/logrocketSetup';
import './lib/observability/tracing';
import * as Sentry from '@sentry/react';
import { analytics } from './services/analytics';

LogRocket.setup();
analytics.init();

import React, { Suspense, lazy, useCallback, useEffect, useMemo, useRef } from 'react';

import * as ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import UserStateSync from './components/RecoilSync/UserState';
import Theme from './components/Theme';
import { AuthState } from './lib/AuthInfoCallback';
import { addAuthInfoCallbackV2, getAuthInfoV2, removeAuthInfoCallbackV2 } from './lib/AuthInfoCallbackV2';
import { ACCESS_DENIED_ERROR, REDIRECT_AFTER_LOGIN_STORAGE_KEY, initV2, refreshLoginToken } from './lib/AuthV2';
import { getLcUserIdFromToken, isNearExpiration, loadSessionJwt } from './lib/jwt';
import { landingPageLink, mfaInProgressRoutes, projectsLink, routes } from './lib/navigation';
import { web3DReport, web3DReportLogger } from './lib/web3DReport';
import { isItarEnv } from './lib/RuntimeParams';
import { useOpenItarEnvDialog } from './state/internal/dialog/itar';
import './scss/globals.scss';
import './scss/vars.scss';
import { useLocationTrace } from './lib/observability/hooks/useLocationTrace';
import { MFA_TOKEN_EXPIRED_ERROR, isMfaTokenExpired } from './lib/AuthMFA';
import { Intercom } from './components/Intercom';
import { useAuthInfoV2, useSetAuthInfoV2 } from './state/external/auth/authInfo';
import { getEnvironmentLabel } from './lib/envUtils';
import { useDebugObserver } from './recoil/debug';
import suspenseWidget from './components/SuspenseWidget';

// Lazy load components
/* eslint-disable */
const BillingPageBody = lazy(() => import('./pages/AccountPage/BillingPageBody'));
const MyAccountPageBody = lazy(() => import('./pages/AccountPage/MyAccountPageBody'));
const SecurityPageBody = lazy(() => import('./pages/AccountPage/SecurityPageBody'));
const SettingsPageBody = lazy(() => import('./pages/AccountPage/SettingsPageBody'));
const UsagePageBody = lazy(() => import('./pages/AccountPage/UsagePageBody'));
const UsagePageAdminBody = lazy(() => import('./pages/AccountPage/UsagePageAdminBody'));
const UsersPageBody = lazy(() => import('./pages/AccountPage/UsersPageBody'));
const AnalysisPage = lazy(() => import('./pages/AnalysisPage'));
const AuthCodePage = lazy(() => import('./pages/AuthCodePage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const GeometryPage = lazy(() => import('./pages/GeometryPage'));
const GetStartedPage = lazy(() => import('./pages/GetStartedPage'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const LibraryPage = lazy(() => import('./pages/LibraryPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const PlatformAdminAdminPage = lazy(() => import('./pages/PlatformAdminPage/AdminAdminBody'));
const PlatformAdminBizopsPageBody = lazy(() => import('./pages/PlatformAdminPage/AdminBizopsBody'));
const PlatformAdminSamplePageBody = lazy(() => import('./pages/PlatformAdminPage/AdminSampleBody'));
const PlatformAdminSupportPageBody = lazy(() => import('./pages/PlatformAdminPage/AdminSupportBody'));
const ProjectListPage = lazy(() => import('./pages/ProjectListPage'));
const ProjectPage = lazy(() => import('./pages/ProjectPage'));
const ResultsPage = lazy(() => import('./pages/ResultsPage'));
const AcceptTermsGoogleActivationPage = lazy(() => import('./pages/auth/AcceptTermsGoogleActivation'));
const AcceptTermsUpdatedPage = lazy(() => import('./pages/auth/AcceptTermsUpdated'));
const ActivateAccountPage = lazy(() => import('./pages/auth/ActivateAccount'));
const ForgotPasswordPage = lazy(() => import('./pages/auth/ForgotPassword'));
const GoogleAuthCallback = lazy(() => import('./pages/auth/GoogleAuthCallback'));
const LoginPage = lazy(() => import('./pages/auth/Login'));
const LoginApp2FA = lazy(() => import('./pages/auth/LoginApp2FA'));
const LoginBackup = lazy(() => import('./pages/auth/LoginBackup'));
const LoginSms2FA = lazy(() => import('./pages/auth/LoginSms2FA'));
const SetPasswordPage = lazy(() => import('./pages/auth/SetPassword'));
const SetupApp2FA = lazy(() => import('./pages/auth/SetupApp2FA'));
const SetupSelect2FA = lazy(() => import('./pages/auth/SetupSelect2FA'));
const SetupSms2FA = lazy(() => import('./pages/auth/SetupSms2FA'));
/* eslint-enable */

if (!window.location.host.includes('localhost')) {
  Sentry.init({
    dsn:
      'https://a6c4e591435d768ef8602c072ba9a662@o4507941768593408.ingest.us.sentry.io/4507941769904128',
    environment: getEnvironmentLabel(),
    integrations: [],
  });
}

// This is temporary to make development easier and to make all new pages easily accessible.
// It will probably be updated once the new auth is implemented.
const PATHS_NOT_REQUIRING_AUTH = [
  '/authcode',
];

// A dummy component that listens to changes to the authenticator and
// updates the recoil state.
const AuthSubscriber = () => {
  const setAuthInfoV2 = useSetAuthInfoV2();

  useEffect(() => {
    // Initialize authentication state. Required early at index page load to
    // drive/intercept authentication redirection & parse URL parameters.
    initV2();
    addAuthInfoCallbackV2(setAuthInfoV2);
    return () => {
      removeAuthInfoCallbackV2(setAuthInfoV2);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (<></>);
};

/**
 * Top-level component for the frontend.
 */
const App = () => {
  const [authInfoV2, setAuthInfoV2] = useAuthInfoV2();
  const authInfoLoginRef = useRef(authInfoV2);
  const location = useLocation();
  const navigate = useNavigate();
  const openItarEnvDialog = useOpenItarEnvDialog();

  const authRequired = useMemo(
    () => !PATHS_NOT_REQUIRING_AUTH.includes(location.pathname.toLowerCase()),
    [location],
  );

  const setupTokenRefresh = () => {
    const refreshInterval = setInterval(() => {
      const currentToken = loadSessionJwt();
      if (currentToken && isNearExpiration(currentToken)) {
        refreshLoginToken().catch((err) => {
          console.error('Failed to refresh token:', err);
        });
      }
    }, 10 * 60 * 1000); // Check every 10 minutes

    return () => clearInterval(refreshInterval);
  };

  const handlePostLogin = useCallback(() => {
    // Logging in in an ITAR environment should always show an ITAR warning dialog
    if (isItarEnv) {
      openItarEnvDialog();
    }
  }, [openItarEnvDialog]);

  // Set up token refresh on component mount
  useEffect(() => {
    const cleanupTokenRefresh = setupTokenRefresh();
    return () => cleanupTokenRefresh();
  }, []);

  // Log recoil state changes to help debug state-related issues.
  useDebugObserver();

  // Send OTel traces when the user navigates to a new tab.
  useLocationTrace();

  // Track page views
  useEffect(() => {
    analytics.page(location.pathname, {
      title: document.title,
    });
  }, [location]);

  useEffect(() => {
    if (!authRequired) {
      return;
    }
    if (authInfoV2.authState === AuthState.UNAUTHENTICATED ||
      authInfoV2.authState === AuthState.AUTHENTICATION_EXPIRED) {
      if (![
        routes.login,
        routes.googleAuthCallback,
        routes.forgotPassword,
        routes.setPassword,
        routes.activateAccount,
        routes.setupSelect2fa,
        routes.setupApp2FA,
        routes.setupSms2FA,
        routes.loginApp2FA,
        routes.loginSms2FA,
        routes.loginBackup,
      ].includes(location.pathname)) {
        if (location.pathname === landingPageLink()) {
          // Do a silent redirect if the user is just opening the root url
          navigate(routes.login, { replace: true });
        } else {
          // If the user attempted to open any other internal page, we should show that their
          // session is expired and should keep that page so we can redirect to it upon login.
          sessionStorage.setItem(REDIRECT_AFTER_LOGIN_STORAGE_KEY, location.pathname);
          navigate(routes.login, {
            replace: true,
            state: {
              message: 'Your session has expired. Please log in to continue.',
            },
          });
        }
        // There are some leftover states that might interfere and cause blank page if we attempt to
        // login directly after being redirected to the login. To workaround that, we need a reload.
        window.location.reload();
      }

      if (
        mfaInProgressRoutes.includes(location.pathname) &&
        location.state?.mfaTokenExpiry &&
        isMfaTokenExpired(location.state?.mfaTokenExpiry)
      ) {
        navigate(routes.login, { state: { error: MFA_TOKEN_EXPIRED_ERROR } });
      }
    } else if (authInfoV2.authState === AuthState.USER_NOT_FOUND) {
      if (location.pathname !== routes.login) {
        navigate(routes.login, { replace: true, state: { error: ACCESS_DENIED_ERROR } });
      }
    } else if (
      // User is logging in for the first time
      // To avoid an infinite loop, navigate only if we're not already on the registration page
      // with state.
      authInfoV2.authState === AuthState.AUTHENTICATION_PENDING_REGISTRATION &&
      location.pathname !== routes.acceptTerms &&
      location.pathname !== routes.activateAccount
    ) {
      navigate(routes.acceptTerms, { replace: true, state: { authState: authInfoV2.authState } });
    } else if (
      // TOS has been updated since user last accepted them
      authInfoV2.authState === AuthState.AUTHENTICATION_PENDING_REACCEPT_TERMS &&
      location.pathname !== routes.acceptTermsUpdated &&
      location.pathname !== routes.activateAccount
    ) {
      navigate(routes.acceptTermsUpdated, {
        replace: true,
        state: {
          authState: authInfoV2.authState,
        },
      });
    }
    if (authInfoV2.authState === AuthState.AUTHENTICATED) {
      const jwt = loadSessionJwt();
      // If the user has logged out from another tab, the current tab will still have an
      // AuthState.AUTHENTICATED state, even though the jwt is not longe available in the browser.
      // The same state may also happen for a very short time on logout because the `webAuth.logout`
      // doesn't fire as fast (and hence the redirect to the login page doesn't happen that fast).
      // That's a fail safe to update the authState to UNAUTHENTICATED if we have such case.
      if (!jwt) {
        setAuthInfoV2({
          authState: AuthState.UNAUTHENTICATED,
          jwt: null,
        });
        return;
      }
      // Idenfity with logrocket.  If we are authenticated, we must have a JWT.
      const id = getLcUserIdFromToken(jwt);
      const { email, name } = jwt;
      LogRocket.identify(id, email, name);
      Sentry.setUser({
        id,
      });
      analytics.identify(id, {
        $email: email,
        $name: name,
        environment: getEnvironmentLabel(),
      });
      analytics.track('User Logged In');

      if ([
        '/',
        routes.login,
        routes.googleAuthCallback,
        routes.acceptTerms,
        routes.acceptTermsUpdated,
        routes.activateAccount,
        routes.loginApp2FA,
        routes.loginSms2FA,
        routes.loginBackup,
        routes.setupApp2FA,
        routes.setupSms2FA,
      ].includes(location.pathname)) {
        const newUrl = sessionStorage.getItem(REDIRECT_AFTER_LOGIN_STORAGE_KEY) || projectsLink();
        sessionStorage.removeItem(REDIRECT_AFTER_LOGIN_STORAGE_KEY);
        navigate(newUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfoV2, location, authRequired]);

  // Record and report WebGL2/WebGPU/SharedArrayBuffer support status for the user's device
  // Vis team records these stats to track GPU and compute capabilities of user devices
  useEffect(() => {
    // Once we know who the user is we can record info about their device's capabilities
    // so that we don't incorrectly count frequent devices as frequent users and bias our
    // statistics
    const authenticated = authInfoV2.authState === AuthState.AUTHENTICATED;
    if (authenticated) {
      const jwt = getAuthInfoV2().jwt!;
      const userId = getLcUserIdFromToken(jwt);
      if (userId) {
        web3DReport(userId).catch((err) => {
          web3DReportLogger.error(`web3DReport failed due to ${err}`);
        });
      }
    }
  }, [authInfoV2]);

  // This effect can be used to call handlers that must execute only when the user signs in.
  // Refreshing a page or going to external page (login, forgot password, etc.) while authenticated,
  // should not call the handlers.
  // This is usefull for when we want to show special welcome popups, like the ITAR popup.
  useEffect(() => {
    if (
      authInfoV2.authState === AuthState.AUTHENTICATED &&
      authInfoLoginRef.current.authState !== AuthState.AUTHENTICATED
    ) {
      authInfoLoginRef.current = authInfoV2;

      // Handlers
      handlePostLogin();
    }
  }, [handlePostLogin, authInfoV2]);

  const content = (
    <ErrorBoundary fallbackRender={({ error }) => (
      <Theme><ErrorPage error={error} /></Theme>
    )}>
      <UserStateSync>
        <Theme>
          <Suspense fallback={suspenseWidget}>
            <Routes>
              <Route element={<ActivateAccountPage />} path={routes.activateAccount} />
              <Route element={<SetupSelect2FA />} path={routes.setupSelect2fa} />
              <Route element={<SetupApp2FA />} path={routes.setupApp2FA} />
              <Route element={<SetupSms2FA />} path={routes.setupSms2FA} />

              <Route element={<LoginPage />} path={routes.login} />
              <Route element={<LoginApp2FA />} path={routes.loginApp2FA} />
              <Route element={<LoginSms2FA />} path={routes.loginSms2FA} />
              <Route element={<LoginBackup />} path={routes.loginBackup} />
              <Route element={<ForgotPasswordPage />} path={routes.forgotPassword} />
              <Route element={<AcceptTermsGoogleActivationPage />} path={routes.acceptTerms} />
              <Route element={<AcceptTermsUpdatedPage />} path={routes.acceptTermsUpdated} />
              <Route element={<SetPasswordPage />} path={routes.setPassword} />
              <Route element={<GoogleAuthCallback />} path={routes.googleAuthCallback} />
              <Route element={<LandingPage />} path={routes.landingPage} />
              <Route element={<GetStartedPage />} path={routes.getStarted} />
              <Route element={<MyAccountPageBody />} path={routes.account} />
              <Route element={<UsagePageBody />} path={routes.accountUsage} />
              <Route element={<UsersPageBody />} path={routes.adminUsers} />
              <Route element={<UsagePageAdminBody />} path={routes.adminUsage} />
              <Route element={<BillingPageBody />} path={routes.adminBilling} />
              <Route element={<SecurityPageBody />} path={routes.adminSecurity} />
              <Route element={<SettingsPageBody />} path={routes.adminSettings} />
              <Route element={<LibraryPage />} path={routes.library} />

              <Route
                element={<PlatformAdminAdminPage />}
                path={routes.platformAdminTeam}
              />
              <Route
                element={<PlatformAdminSupportPageBody />}
                path={routes.platformAdminSupport}
              />
              <Route
                element={<PlatformAdminSamplePageBody />}
                path={routes.platformAdminSample}
              />
              <Route
                element={<PlatformAdminBizopsPageBody />}
                path={routes.platformAdminBizops}
              />

              <Route element={<ProjectListPage />} path={routes.projectList} />
              <Route
                element={<AnalysisPage />}
                path={routes.explorationJob}
              />
              <Route
                element={<AnalysisPage />}
                path={routes.exploration}
              />
              <Route
                element={<AnalysisPage />}
                path={routes.simulation}
              />
              <Route
                element={<ResultsPage />}
                path={routes.resultsPage}
              />
              <Route
                element={<ResultsPage />}
                path={routes.resultPageAlt}
              />
              <Route
                element={<GeometryPage />}
                path={routes.geometryV1}
              />
              <Route
                element={<GeometryPage />}
                path={routes.geometryV2}
              />
              <Route
                element={<ProjectPage />}
                path={routes.projectPage}
              />
              {/* workflow and job are just for backwards compatibility for old
                links. May not be neccessary. Assumes the link is a simulation. */}
              <Route
                element={<AnalysisPage />}
                path={routes.analysisPage}
              />
              <Route
                element={<AnalysisPage />}
                path={routes.analysisPageAlt}
              />
              <Route
                element={<AuthCodePage />}
                path={routes.authCode}
              />
              <Route element={<NotFoundPage />} path={routes.notFound} />
            </Routes>
          </Suspense>
          <Intercom />
        </Theme>
      </UserStateSync>
    </ErrorBoundary>
  );
  return content;
};

ReactDOM.render(
  <BrowserRouter>
    <RecoilRoot>
      <AuthSubscriber />
      {/* TODO(bamo): this suspense will catch any unfetched state and render
            a blank page.  Once we have made suspenses to represent empty states
            for sub-components, we should remove this. */}
      <React.Suspense fallback={<div />}>
        <App />
      </React.Suspense>
    </RecoilRoot>
  </BrowserRouter>,
  document.getElementById('root'),
);
