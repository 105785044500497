// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/geometry/geometry.proto (package luminary.proto.geometry, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Cone, Cube, Cylinder, HalfSphere, Sphere, Torus } from "../cad/shape_pb.js";
import { RegularChop, RegularIntersection, RegularSubtraction, RegularUnion } from "../cad/boolean_pb.js";
import { AugmentedMatrix, Reflection, Rotation, Scaling, Translation } from "../cad/transformation_pb.js";
import { LCStatus } from "../lcstatus/lcstatus_pb.js";

/**
 * @generated from enum luminary.proto.geometry.EntityType
 */
export const EntityType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.geometry.EntityType",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "BODY"},
    {no: 2, name: "VOLUME"},
    {no: 3, name: "FACE"},
    {no: 4, name: "EDGE"},
    {no: 5, name: "VERTEX"},
  ],
);

/**
 * @generated from message luminary.proto.geometry.Import
 */
export const Import = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Import",
  () => [
    { no: 1, name: "geometry_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scaling", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message luminary.proto.geometry.Create
 */
export const Create = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Create",
  () => [
    { no: 1, name: "sphere", kind: "message", T: Sphere, oneof: "shape" },
    { no: 2, name: "box", kind: "message", T: Cube, oneof: "shape" },
    { no: 3, name: "cylinder", kind: "message", T: Cylinder, oneof: "shape" },
    { no: 4, name: "torus", kind: "message", T: Torus, oneof: "shape" },
    { no: 5, name: "cone", kind: "message", T: Cone, oneof: "shape" },
    { no: 6, name: "half_sphere", kind: "message", T: HalfSphere, oneof: "shape" },
  ],
);

/**
 * @generated from message luminary.proto.geometry.Delete
 */
export const Delete = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Delete",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(EntityType) },
    { no: 2, name: "ids", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.geometry.Boolean
 */
export const Boolean = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Boolean",
  () => [
    { no: 1, name: "reg_union", kind: "message", T: RegularUnion, oneof: "op" },
    { no: 2, name: "reg_subtraction", kind: "message", T: RegularSubtraction, oneof: "op" },
    { no: 3, name: "reg_intersection", kind: "message", T: RegularIntersection, oneof: "op" },
    { no: 4, name: "reg_chop", kind: "message", T: RegularChop, oneof: "op" },
  ],
);

/**
 * For now we don't allow selecting the bodies to be imprinted. All bodies
 * will be imprinted against each order in arbitrary order.
 *
 * @generated from message luminary.proto.geometry.Imprint
 */
export const Imprint = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Imprint",
  [],
);

/**
 * Transformation operation.
 *
 * @generated from message luminary.proto.geometry.Transform
 */
export const Transform = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Transform",
  () => [
    { no: 1, name: "body", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "matrix", kind: "message", T: AugmentedMatrix, oneof: "t" },
    { no: 3, name: "translation", kind: "message", T: Translation, oneof: "t" },
    { no: 4, name: "rotation", kind: "message", T: Rotation, oneof: "t" },
    { no: 5, name: "scaling", kind: "message", T: Scaling, oneof: "t" },
    { no: 6, name: "reflection", kind: "message", T: Reflection, oneof: "t" },
    { no: 7, name: "keep", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Shrinkwrap operation.
 *
 * We will add more fields as we need them.
 *
 * @generated from message luminary.proto.geometry.Shrinkwrap
 */
export const Shrinkwrap = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Shrinkwrap",
  [],
);

/**
 * Farfield is an operation that combines a create operation with a boolean
 * subtraction.
 *
 * @generated from message luminary.proto.geometry.Farfield
 */
export const Farfield = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Farfield",
  () => [
    { no: 1, name: "create", kind: "message", T: Create },
    { no: 2, name: "bodies", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 3, name: "keep_source_bodies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "keep_tool_bodies", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Pattern operation.
 *
 * @generated from message luminary.proto.geometry.Pattern
 */
export const Pattern = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Pattern",
  () => [
    { no: 1, name: "body", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 2, name: "direction", kind: "message", T: Pattern_Direction },
  ],
);

/**
 * Describes the direction of the pattern.
 *
 * @generated from message luminary.proto.geometry.Pattern.Direction
 */
export const Pattern_Direction = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Pattern.Direction",
  () => [
    { no: 1, name: "quantity", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "symmetric", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "linear_spacing", kind: "message", T: Translation, oneof: "type" },
    { no: 4, name: "circular_distribution", kind: "message", T: Pattern_Direction_Circular, oneof: "type" },
  ],
  {localName: "Pattern_Direction"},
);

/**
 * Circular rotation.
 *
 * @generated from message luminary.proto.geometry.Pattern.Direction.Circular
 */
export const Pattern_Direction_Circular = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Pattern.Direction.Circular",
  () => [
    { no: 1, name: "rotation", kind: "message", T: Rotation },
    { no: 2, name: "full", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "Pattern_Direction_Circular"},
);

/**
 * Describes the issues associated with a given feature with ID feature_id.
 *
 * @generated from message luminary.proto.geometry.FeatureIssues
 */
export const FeatureIssues = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.FeatureIssues",
  () => [
    { no: 1, name: "issues", kind: "message", T: LCStatus, repeated: true },
    { no: 2, name: "feature_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.geometry.Feature
 */
export const Feature = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Feature",
  () => [
    { no: 1, name: "import", kind: "message", T: Import, oneof: "operation" },
    { no: 2, name: "create", kind: "message", T: Create, oneof: "operation" },
    { no: 3, name: "delete", kind: "message", T: Delete, oneof: "operation" },
    { no: 4, name: "boolean", kind: "message", T: Boolean, oneof: "operation" },
    { no: 7, name: "imprint", kind: "message", T: Imprint, oneof: "operation" },
    { no: 12, name: "transform", kind: "message", T: Transform, oneof: "operation" },
    { no: 13, name: "shrinkwrap", kind: "message", T: Shrinkwrap, oneof: "operation" },
    { no: 14, name: "farfield", kind: "message", T: Farfield, oneof: "operation" },
    { no: 15, name: "pattern", kind: "message", T: Pattern, oneof: "operation" },
    { no: 5, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "feature_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Add a tag in a failsafe manner.
 * If entities are defined, add the tag.
 * Otherwise, add the tag to all entities.
 *
 * @generated from message luminary.proto.geometry.CreateOrUpdateTag
 */
export const CreateOrUpdateTag = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.CreateOrUpdateTag",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bodies", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 3, name: "faces", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * Rename an existing tag in a failsafe manner.
 *
 * @generated from message luminary.proto.geometry.RenameTag
 */
export const RenameTag = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.RenameTag",
  () => [
    { no: 1, name: "old_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Delete a tag in a failsafe manner.
 * If entities are defined, remove the tag from those entities.
 * Otherwise, remove the tag from all entities.
 *
 * @generated from message luminary.proto.geometry.DeleteTag
 */
export const DeleteTag = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.DeleteTag",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bodies", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 3, name: "faces", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ],
);

/**
 * Removes multiple tags in a failsafe manner.
 *
 * @generated from message luminary.proto.geometry.DeleteTags
 */
export const DeleteTags = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.DeleteTags",
  () => [
    { no: 1, name: "names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.geometry.Modification
 */
export const Modification = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Modification",
  () => [
    { no: 1, name: "mod_type", kind: "enum", T: proto3.getEnumType(Modification_ModificationType) },
    { no: 2, name: "feature", kind: "message", T: Feature },
    { no: 3, name: "create_or_update_tag", kind: "message", T: CreateOrUpdateTag },
    { no: 4, name: "rename_tag", kind: "message", T: RenameTag },
    { no: 5, name: "delete_tag", kind: "message", T: DeleteTag },
    { no: 6, name: "delete_tags", kind: "message", T: DeleteTags },
  ],
);

/**
 * @generated from enum luminary.proto.geometry.Modification.ModificationType
 */
export const Modification_ModificationType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.geometry.Modification.ModificationType",
  [
    {no: 0, name: "MODIFICATION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MODIFICATION_TYPE_CREATE_FEATURE", localName: "CREATE_FEATURE"},
    {no: 2, name: "MODIFICATION_TYPE_UPDATE_FEATURE", localName: "UPDATE_FEATURE"},
    {no: 3, name: "MODIFICATION_TYPE_DELETE_FEATURE", localName: "DELETE_FEATURE"},
    {no: 4, name: "MODIFICATION_TYPE_UNDO", localName: "UNDO"},
    {no: 5, name: "MODIFICATION_TYPE_REDO", localName: "REDO"},
    {no: 6, name: "MODIFICATION_TYPE_RENAME_FEATURE", localName: "RENAME_FEATURE"},
    {no: 7, name: "MODIFICATION_TYPE_CREATE_TAG", localName: "CREATE_TAG"},
    {no: 8, name: "MODIFICATION_TYPE_UPDATE_TAG", localName: "UPDATE_TAG"},
    {no: 9, name: "MODIFICATION_TYPE_DELETE_TAG", localName: "DELETE_TAG"},
    {no: 10, name: "MODIFICATION_TYPE_RENAME_TAG", localName: "RENAME_TAG"},
    {no: 11, name: "MODIFICATION_TYPE_DELETE_TAGS", localName: "DELETE_TAGS"},
  ],
);

/**
 * @generated from message luminary.proto.geometry.Volume
 */
export const Volume = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.geometry.Volume",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

